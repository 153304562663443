import * as React from "react";
import { useState, useEffect } from 'react';
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import * as headerStyles from '../styles/header.module.css';


// Header Links
const Links = [
  {
    path: '/',
    name: "Home"
  },
  {
    path: '/shop',
    name: "Shop"
  },
  {
    path: '/galleries',
    name: "Gallery"
  },
  {
    path: '/contact',
    name: "Contact"
  }
];

const Header = () => {
  const [open, setOpen] = useState(false);
  const [headerClass, setHeaderClass] = useState(headerStyles.startHeader);

  const handleScroll = React.useCallback((e) => {
    window.scrollY < 1 ?
    setHeaderClass(headerStyles.startHeader) :
    setHeaderClass(headerStyles.header)
    
  }, []);

  useEffect(() => {
    document.body.classList.toggle(headerStyles.navOpen, open)
  }, [open])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [handleScroll]);
    
  return (
    <header className={headerClass}>
      <div className={headerStyles.top}>
        <div className={headerStyles.logoContainer}>
          Andre Cho Fine Arts Studio
        </div>
      </div>
      <button 
        onClick={() => setOpen(prev => !prev)}
        className={headerStyles.navToggle} 
        aria-label="toggle navigation"
      >
        <span className={headerStyles.hamburger}></span>
      </button>
      <div>
        <nav className={headerStyles.navContainer}>
          <div className={headerStyles.navLinks}>
            {/* Navigation */}
            {Links.map((navLink, id) => {
              return (
                <Link
                  onClick={() => setOpen(prev => !prev)}
                  key={id}
                  className={headerStyles.navLink}
                  to={navLink.path}
                >
                  {navLink.name}
                </Link>
              )
            })}
            {/* Booking Link */}
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
