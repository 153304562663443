import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

import * as footerStyles from "../styles/footer.module.css";

const FooterColumn = ({ children, grow }) => {
    return (
        <div 
            className={footerStyles.footerCol}
            style={
                grow ? 
                {flexGrow: 2} :
                null
            }
        >
            {children}
        </div>
    )
};

const FooterTitle = ({ children, center }) => {
    return (
        
        <h3 
            style={
                center ?
                {textAlign: `center`} :
                null
            } 
            className={footerStyles.footerTitle}
        >
            {children}
        </h3>
    )
}

const IntFooterLink = ({ link, children }) => {
    return (
        <Link className={footerStyles.footerLink} to={link}>{children}</Link>
    )
}

const ExFooterLink = ({ link, children }) => {
    return (
        <a href={link} rel="noreferrer" target="_blank" className={footerStyles.footerLink}>{children}</a>
    )
}

const SocialLink = ({ name, link, children, color }) => {
    return (
        <div className={footerStyles.socialLinkContainer}>
            <a 
                aria-label={name}
                href={link} 
                target="_blank" 
                className={footerStyles.socialLink}
                style={{
                    color: `${color}`
                }}
                rel="noreferrer"
            >
                {children}
            </a>
        </div>
    )
}

const Footer = () => {
    const data = useStaticQuery(graphql`
        query socialMediaQuery {
            site {
                siteMetadata {
                    socials {
                        instagram
                    }
                }
            }
        }
    `);
    
    return (
        <div className={footerStyles.footerWrapper}>
            <footer className={footerStyles.footer}>
                <FooterColumn grow={true}>
                    <FooterTitle center={true}>Follow Me</FooterTitle>
                    <div className={footerStyles.socialLinks}>
                        <SocialLink name="Instagram" color="#d73462" link={data.site.siteMetadata?.socials.instagram}>
                            <FontAwesomeIcon icon={faInstagram} />
                        </SocialLink>
                    </div>
                </FooterColumn>
                <FooterColumn>
                    <FooterTitle>My Work</FooterTitle>
                    <div>
                        <IntFooterLink link="/galleries/Commissions">Commissions</IntFooterLink>
                        <IntFooterLink link="/galleries/Originals">Originals</IntFooterLink>
                        {/* <IntFooterLink link="/services">Waxing</IntFooterLink> */}
                    </div>
                </FooterColumn>
                <FooterColumn>
                    <FooterTitle>Questions?</FooterTitle>
                    <div>
                        <IntFooterLink link="/support">Contact</IntFooterLink>
                    </div>
                </FooterColumn>
            </footer>
        </div>
    );
}

export default Footer;