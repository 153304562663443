 import * as React from "react";
 import { useStaticQuery, graphql } from "gatsby";

 import Header from './header.js';
 import Footer from './footer.js';
 
 const Layout = ({ children }) => {
 
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `);
 
   return (
     <>
       <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <div>
            <main>{children}</main>
        </div>
        <Footer />
     </>
   )
 }
 
 export default Layout
 